import { lazyImport } from '@/utils/lazyImport';
import React from 'react';

const { MedicineImageGallery } = lazyImport(
  () => import('@/features/medicines'),
  'MedicineImageGallery',
);

const { Medicines } = lazyImport(
  () => import('@/features/medicines'),
  'Medicines',
);
const { MyMedicines } = lazyImport(
  () => import('@/features/myMedicines'),
  'MyMedicines',
);
const { MedicineSearch } = lazyImport(
  () => import('@/features/medicineSearch'),
  'MedicineSearch',
);
const { ICDSearch } = lazyImport(
  () => import('@/features/ICDSearch'),
  'ICDSearch',
);
const { TreatmentSearch } = lazyImport(
  () => import('@/features/treatmentSearch'),
  'TreatmentSearch',
);
const { Feedbacks } = lazyImport(
  () => import('@/features/feedbacks'),
  'Feedbacks',
);
const { PurchaseInfo } = lazyImport(
  () => import('@/features/purchaseInfo'),
  'PurchaseInfo',
);
const { Subscribe } = lazyImport(
  () => import('@/features/subscribe'),
  'Subscribe',
);
// const { Setting } = lazyImport(() => import('@/features/setting'), 'Setting');
const { Verify } = lazyImport(() => import('@/features/verify'), 'Verify');

const { Admin } = lazyImport(() => import('@/features/admin'), 'Admin');

export const protectedRoutes = [
  {
    path: '/medicines',
    element: <Medicines />,
  },
  {
    path: '/my-medicines',
    element: <MyMedicines />,
  },
  {
    path: '/medicine-search',
    element: <MedicineSearch />,
  },
  {
    path: '/icd-search',
    element: <ICDSearch />,
  },
  {
    path: '/treatment-search',
    element: <TreatmentSearch />,
  },
  {
    path: '/feedbacks',
    element: <Feedbacks />,
  },
  {
    path: '/purchaseInfo',
    element: <PurchaseInfo />,
  },
  {
    path: '/subscribe',
    element: <Subscribe />,
  },
  {
    path: '/admin',
    element: <Admin />,
  },
  {
    path: '/verify',
    element: <Verify />,
  },
  // { path: '/setting', element: <Setting /> },
];
