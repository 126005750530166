import { TableModal } from '@/components/Elements/Modal';
import { updateMedicineOrder } from '@/features/myMedicines/store/myMedicinesSlice';
import { useBetaMode } from '@/hooks/useBetaMode';
import {
  downloadInsuranceDetail,
  downloadPackageInsert,
} from '@/libs/firebase/storage';
import {
  FileImageOutlined,
  InfoCircleOutlined,
  MenuOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { notification, Table } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import clsx from 'clsx';
import React, { forwardRef, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMyMedicineData } from '../hooks/useMyMedicineData';
import './MyMedicineTable.css';

const DragHandle = forwardRef(({ isEditing, listeners }, ref) =>
  isEditing ? (
    <MenuOutlined
      style={{
        cursor: 'move',
        color: '#0a2844',
        touchAction: 'none',
      }}
      ref={ref}
      {...listeners}
    />
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className="w-2 h-2">
      {/* <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
      <path
        fill="#7c9ab6"
        d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256 352C309 352 352 309 352 256C352 202.1 309 160 256 160C202.1 160 160 202.1 160 256C160 309 202.1 352 256 352z"
      />
    </svg>
  ),
);
DragHandle.displayName = 'DragHandle';

// const SortableItem = SortableElement((props) => {
//   const bgColor = props?.children?.[0]?.props?.record?.['table-color'];

//   return (
//     <tr
//       {...props}
//       className={clsx(
//         'ant-table-row',
//         !!bgColor && `my-row-${bgColor}`,
//         props.className,
//       )}
//     />
//   );
// });
// const SortableBody = SortableContainer((props) => <tbody {...props} />);

// const combineOrder = (medicineData, orderData) => {
//   const combinedOrder = medicineData.map((medicine, idx) => {
//     const updatedMedicine = { ...medicine };
//     const assignedIndex = orderData?.[medicine?.key] ?? idx;

//     updatedMedicine.index = assignedIndex;

//     return updatedMedicine;
//   });

//   return combinedOrder.sort((a, b) => a.index - b.index);
// };

// const combineNote = (rawData, note) => {
//   if (!rawData) return [];

//   const combinedNote = rawData.map((medicine) => ({
//     ...medicine,
//     ...note[medicine.key],
//   }));

//   return combinedNote;
// };

// const Wrapper = ({ children, ...props }) => {
//   <div>{children}</div>;
// };

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  const bgColor = children?.[0]?.props?.record['table-color'];

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={clsx(!!bgColor && `my-row-${bgColor}`)}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          // console.log('sort' + child.props.record.key);
          return React.cloneElement(child, {
            children: child.props.render({
              ref: setActivatorNodeRef,
              listeners: listeners,
            }),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const MyMedicineTable = React.memo(({ tableData }) => {
  const dispatch = useDispatch();
  const { section, data } = tableData;
  const isEditing = useSelector((state) => state.control.control.isEditing);
  const selected = useSelector(
    (state) => state.control.userPreference.selectedMedicineNotes,
  );
  const insuranceData = useSelector((state) => state.medicines.insurances);

  const [medicineData, setMedicineData] = useMyMedicineData({ tableData });

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: '5%',
      className: 'drag-visible',
      isEditing: isEditing,
      render: ({ ref, listeners }) => (
        <div className="flex justify-center">
          <DragHandle isEditing={isEditing} ref={ref} listeners={listeners} />
        </div>
      ),
    },
    {
      title: () => <div className="text-center">健保碼</div>,
      dataIndex: 'key',
      width: '8%',
      shouldCellUpdate: false,
      render: (text, record, index) => {
        const hasInsuranceCode = !text.startsWith('----------');

        return (
          <div
            className="text-center"
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'pre-wrap',
            }}>
            {hasInsuranceCode ? text : '------'}
            <br />
            <span className="text-blue-500">
              {selected['ATCCode'] && record['ATC-code']}
            </span>
          </div>
        );
      },
    },
    {
      title: () => <div className="text-center">代碼</div>,
      dataIndex: 'code',
      width: '7%',
      editable: true,
      render: (text, record) => (
        <div
          className="text-center"
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}>
          {text}
        </div>
      ),
    },
    {
      title: () => <div className="text-center">藥物名稱</div>,
      dataIndex: 'name',
      width: '13%',
      render: (text, record) => (
        <div className="text-center" style={{ whiteSpace: 'pre-line' }}>
          {text}
          <br />
          <span className="text-blue-500">
            {selected['noteCompany'] && record['note-company']}
          </span>
        </div>
      ),
    },
    {
      title: '成分',
      dataIndex: 'ingredients',
      width: '16%',
      render: (text, record) => (
        <div
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}>
          {text}
        </div>
      ),
    },
    {
      title: '用法',
      dataIndex: 'usage',
      width: '10%',
      render: (text, record) => {
        const column = (
          <div>
            {selected['usageSummary'] && (
              <div className="">{record['usage-summary']}</div>
            )}
            {selected['usageNote'] && (
              <div className="">{record['usage-note']}</div>
            )}
            {selected['usageAdd'] && (
              <div className="">{record['usage-add']}</div>
            )}
          </div>
        );

        return column;
      },
    },
    {
      title: '健保給付與備註',
      dataIndex: 'note',
      width: '27%',
      render: (text, record) => {
        const column = (
          <div style={{ whiteSpace: 'pre-line' }}>
            {selected['blackSummary'] && (
              <div className="">{record['black-summary']}</div>
            )}
            {selected['blackNote'] && (
              <div className="">{record['black-note']}</div>
            )}
            {selected['blackAdd'] && (
              <div className="">{record['black-add']}</div>
            )}
            {selected['purpleSummary'] && (
              <div className="text-purple-500">{record['purple-summary']}</div>
            )}
            {selected['purpleNote'] && (
              <div className="text-purple-500">{record['purple-note']}</div>
            )}
            {selected['purpleAdd'] && (
              <div className="text-purple-500">{record['purple-add']}</div>
            )}
            {selected['orangeSummary'] && (
              <div className="text-orange-500">{record['orange-summary']}</div>
            )}
            {selected['orangeNote'] && (
              <div className="text-orange-500">{record['orange-note']}</div>
            )}
            {selected['orangeAdd'] && (
              <div className="text-orange-500">{record['orange-add']}</div>
            )}
            {selected['redSummary'] && (
              <div className="text-red-500">{record['red-summary']}</div>
            )}
            {selected['redNote'] && (
              <div className="text-red-500">{record['red-note']}</div>
            )}
            {selected['redAdd'] && (
              <div className="text-red-500">{record['red-add']}</div>
            )}
            {selected['blueSummary'] && (
              <div className="text-blue-500">{record['blue-summary']}</div>
            )}
            {selected['blueNote'] && (
              <div className="text-blue-500">{record['blue-note']}</div>
            )}
            {selected['blueAdd'] && (
              <div className="text-blue-500">{record['blue-add']}</div>
            )}
            {selected['green'] && (
              <div className="text-green-500">
                {record['note-code']?.split('$')?.map((item) => {
                  if (item === '') return;

                  let insurance = insuranceData.find((insurance) => {
                    // console.log('code', insurance?.insurance_code, 'item', item);
                    return insurance?.insurance_code === item;
                  });

                  let content = (
                    <TableModal
                      key={item}
                      title={item}
                      content={insurance?.['content']}
                    />
                  );

                  if (insurance?.['note_code'] !== '') {
                    // console.log(insurance?.['note_code']);
                    content = (
                      <div key={item}>
                        <TableModal
                          key={item}
                          title={item}
                          content={insurance?.['content']}
                        />
                        {insurance?.['note_code'].split('$').map((code) => (
                          <a
                            onClick={() =>
                              downloadInsuranceDetail({
                                key: code,
                              })
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center text-green-500 hover:underline hover:text-green-400"
                            key={code}>
                            {code}
                          </a>
                        ))}
                      </div>
                    );
                  }

                  return content;
                })}
                {record['note-green']}
              </div>
            )}
          </div>
        );

        return column;
      },
    },
    {
      title: () => <div className="text-center">狀態</div>,
      dataIndex: 'status',
      width: '10%',
      editable: true,
      render: (text, record) => (
        <div
          className="text-center"
          style={{
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}>
          {text}
        </div>
      ),
    },
    {
      title: () => (
        <div className="text-center">
          <div>圖片</div>
          <div>仿單</div>
        </div>
      ),
      dataIndex: 'image',
      width: '5%',
      shouldCellUpdate: false,
      render: (text, record) => (
        <div className="flex flex-col justify-center items-center gap-2 text-xl">
          <Link
            to={`/medicines/image/${record.key}?medicine=${record.name}`}
            target="_blank">
            <FileImageOutlined />
          </Link>
          <a
            onClick={() => downloadPackageInsert(record)}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center">
            <InfoCircleOutlined />
          </a>
        </div>
      ),
    },
  ];

  // const pickedMedicine = useMemo(
  //   () => data.filter((medicine) => pickedMedicineKey.includes(medicine.key)),
  //   [pickedMedicineKey],
  // );

  // const combinedMedicineData = useMemo(() => {
  //   const orderedData = combineOrder(pickedMedicine, medicineOrder);
  //   const combinedNoteData = combineNote(orderedData, myNote);
  //   return combinedNoteData;
  // }, [data, medicineOrder, myNote]);

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = medicineData.findIndex((i) => i.key === active.id);
      const overIndex = medicineData.findIndex((i) => i.key === over?.id);
      const updatedMedicineData = arrayMove(
        medicineData,
        activeIndex,
        overIndex,
      );

      setMedicineData(updatedMedicineData);

      const orderRecord = {};

      updatedMedicineData.forEach((medicine, idx) => {
        orderRecord[medicine['key']] = idx;
      });

      dispatch(updateMedicineOrder({ section, content: orderRecord }));
    }
  };

  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   if (oldIndex !== newIndex) {
  //     const newData = arrayMoveImmutable(
  //       combinedMedicineData.slice(),
  //       oldIndex,
  //       newIndex,
  //     ).filter((el) => !!el);

  //     const orderRecord = {};

  //     newData.forEach((medicine, idx) => {
  //       orderRecord[medicine['key']] = idx;
  //     });

  //     dispatch(updateMedicineOrder({ section, content: orderRecord }));
  //   }
  // };

  // const DraggableContainer = (props) => (
  //   <SortableBody
  //     useDragHandle
  //     disableAutoscroll
  //     helperClass="row-dragging"
  //     onSortEnd={onSortEnd}
  //     {...props}
  //   />
  // );

  // const DraggableBodyRow = ({ className, style, ...restProps }) => {
  //   // function findIndex base on Table rowKey props and should always be a right array index
  //   const index = combinedMedicineData.findIndex(
  //     (x) => x.index === restProps['data-row-key'],
  //   );

  //   return <SortableItem index={index} {...restProps} disabled={!isEditing} />;
  // };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={medicineData?.map((i) => i.key)}
        strategy={verticalListSortingStrategy}>
        <Table
          id="myTable"
          bordered
          pagination={false}
          dataSource={medicineData}
          columns={columns}
          rowKey="key"
          style={{ fontSize: '24px' }}
          components={{
            body: {
              // wrapper: Wrapper,
              row: Row,
            },
          }}
        />
      </SortableContext>
    </DndContext>
  );
});

MyMedicineTable.displayName = 'MyMedicineTable';
